<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="leaves"
      item-key="meta.id"
      multi-sort
      sort-by="duration.start.firebaseFormat"
      :sort-desc="true"
      :items-per-page="15"
      :footer-props="footerProps"
      :search="search"
      class="elevation-2"
      max-height="800px auto"
      @click:row="routeDetails"
    >
      <template v-slot:top>
        <v-card flat>
          <v-card-subtitle>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="search"
                  outlined
                  append-icon="mdi-magnify"
                  label="Nach Personen suchen"
                  hide-details="auto"
                  :dense="$vuetify.breakpoint.smAndDown"
                ></v-text-field>
              </v-col>
              <v-col>
                <span class="mr-4">Filter nach Typ</span>
                <v-chip-group
                  v-model="leavesPersonFilter"
                  mandatory
                  active-class="primary--text"
                >
                  <v-chip
                    label
                    value="all"
                    class="transparent-background font-weight-medium mr-2"
                    >Alle
                  </v-chip>
                  <v-chip
                    label
                    value="own"
                    class="transparent-background font-weight-medium"
                    >Eigene</v-chip
                  >
                </v-chip-group>
              </v-col>
              <v-col>
                <span class="mr-4">Filter nach Status</span>
                <v-chip-group
                  v-model="leavesStateFilter"
                  mandatory
                  active-class="primary--text"
                >
                  <v-chip
                    label
                    value="all"
                    class="transparent-background font-weight-medium mr-2"
                    >Alle</v-chip
                  >
                  <v-chip
                    label
                    value="active"
                    class="transparent-background font-weight-medium mr-2"
                    >Aktiv</v-chip
                  >
                  <v-chip
                    label
                    value="upcoming"
                    class="transparent-background font-weight-medium mr-2"
                    >Bevorstehend</v-chip
                  >
                </v-chip-group>
              </v-col>
              <!-- <v-col>
                            <span class="mr-4">Filter nach Person</span>
                            <v-autocomplete
                              :items="[]"
                              outlined
                              persistent-hint
                              return-object
                              label="Person auswählen"
                              item-text="displayName"
                              item-value="uid"
                              hide-details="auto"
                              dense
                            ></v-autocomplete>
                          </v-col> -->
            </v-row>
          </v-card-subtitle>
        </v-card>
        <v-divider></v-divider>
      </template>

      <template v-slot:group.header="{ items, isOpen, toggle }">
        <th :colspan="headers.length">
          <v-icon @click="toggle"
            >{{ isOpen ? "mdi-chevron-up" : "mdi-chevron-down" }}
          </v-icon>
          {{ stripPrefix(items[0].groupBy) }}
        </th>
      </template>
      <template v-slot:[`item.user.displayName`]="{ item }">
        <div class="d-flex flex-column">
          <span
            class="d-block font-weight-semibold text--primary text-truncate"
            >{{ item.user.displayName }}</span
          >
          <!-- <small class="grey--text">{{ item.group.title }}</small> -->
          <small class="grey--text">Benutzergruppe</small>
        </div>
      </template>
      <template v-slot:[`item.progress`]="{ item }">
        <date-progress-bar
          :start="item.duration.start.firebaseFormat"
          :end="item.duration.end.firebaseFormat"
          color="blue lighten-4"
        ></date-progress-bar>
      </template>
      <template #[`item.full_name`]="{ item }">
        <div class="d-flex flex-column">
          <span
            class="d-block font-weight-semibold text--primary text-truncate"
            >{{ item.full_name }}</span
          >
          <small>{{ item.post }}</small>
        </div>
      </template>
      <!-- status -->
      <template #[`item.status`]="{ item }">
        <leave-status-chip :status="item.status" small> </leave-status-chip>
      </template>
      <template #[`item.reason.id`]="{ item }">
        <leave-reason-chip :reason="item.reason" small></leave-reason-chip>
      </template>
      <template v-slot:no-data>
        <div
          style="
            height: 300px;
            display: flex;
            justify-content: center;
            align-items: center;
          "
        >
          <p class="subtitle-1">Keine Abwesenheiten vorhanden.</p>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import DateProgressBar from "@/components/_systemwide/DateProgressBar.vue";
import LeaveStatusChip from "@/components/availability/leaves/LeaveStatusChip.vue";
import LeaveReasonChip from "@/components/availability/leaves/LeaveReasonChip.vue";
export default {
  name: "leaves-data-table",
  props: {
    leaves: {
      type: Array,
      required: true,
    },
  },
  components: {
    LeaveStatusChip,
    LeaveReasonChip,
    DateProgressBar,
  },
  data() {
    return {
      search: "",
      leavesPersonFilter: "own",
      leavesStateFilter: "active",
      footerProps: {
        "items-per-page-options": [15, 25, 50, 100, -1],
      },
      headers: [
        { text: "Name", value: "user.displayName" },
        { text: "Status", value: "status" },
        { text: "Startzeitpunkt", value: "duration.start.timestamp" },
        { text: "Endzeitpunkt", value: "duration.end.timestamp" },
        { text: "Fortschritt", value: "progress" },
        { text: "Grund", value: "reason.id" },
      ],
    };
  },
  methods: {
    routeDetails(item) {
      this.$router.push({
        name: "availability-leaves-details",
        params: {
          organizationId: this.$route.params.organizationId,
          itemId: item.meta.id,
        },
      });
    },
    reasonText(reasonId) {
      switch (reasonId) {
        case "0":
          return "Keine Angabe";
        case "1":
          return "Verpflichtungen/Verbindlichkeiten/Termine";
        case "2":
          return "Nicht in Stadt";
        case "3":
          return "Keine Diensttauglichkeit";
        case "4":
          return "Eingeschränkte Diensttauglichkeit";
        case "8":
          return "Beurlaubung";
        case "9":
          return "Sonstiges";
        default:
          return "Fehler";
      }
    },
  },
};
</script>
