<template>
  <div>
    <NavigationBar
      titel="Abwesenheiten"
      isPreview
      :actions="actions"
    ></NavigationBar>
    <TippCard hintID="Verfuegbarkeit_Abwesenheiten_1_V1" text="Text">
    </TippCard>
    <section>
      <template>
        <v-container>
          <v-row
            v-if="$store.state.organization.membership.availability.isOnLeave"
            justify="center"
            align="center"
          >
            <v-col cols="12">
              <active-leave-card
                :end="
                  $store.state.organization.membership.availability.activeLeave
                    .end.timestamp
                "
                :id="
                  $store.state.organization.membership.availability.activeLeave
                    .id
                "
              ></active-leave-card>
            </v-col>
          </v-row>
          <leaves-statistics-overview> </leaves-statistics-overview>
          <v-row>
            <v-col cols="12">
              <Subheader icon="mdi-archive" title="Abwesenheiten">
                <template v-slot:actions>
                  <v-btn-toggle v-model="helpers.displayMode" dense mandatory>
                    <v-btn depressed outlined icon value="table">
                      <v-icon
                        :color="$vuetify.theme.dark ? '' : 'grey darken-1'"
                        >mdi-table</v-icon
                      >
                    </v-btn>
                    <v-btn outlined icon value="calendar">
                      <v-icon
                        :color="$vuetify.theme.dark ? '' : 'grey darken-1'"
                        >mdi-calendar-blank</v-icon
                      >
                    </v-btn>
                  </v-btn-toggle>
                </template>
              </Subheader>
              <div v-if="helpers.displayMode === 'table'" class="mt-7">
                <v-skeleton-loader
                  v-if="helpers.dataIsLoading"
                  elevation="2"
                  type="table"
                ></v-skeleton-loader>
                <leaves-data-table
                  v-else
                  :leaves="leavesOutput"
                ></leaves-data-table>
              </div>
              <div v-else class="mt-7">
                <v-skeleton-loader
                  v-if="helpers.dataIsLoading"
                  elevation="2"
                  type="calendar"
                ></v-skeleton-loader>
                <leaves-calendar
                  v-else
                  :leaves="leavesOutput"
                ></leaves-calendar>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <section v-if="checkIfSupport">
      <support-tools :sources="code"></support-tools>
    </section>
  </div>
</template>

<script>
import { auth } from "@/firebase";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import Subheader from "@/components/_systemwide/Subheader.vue";
import SupportTools from "@/components/_system/helpers/SupportTools.vue";
import TippCard from "@/components/_systemwide/Tipp_Card.vue";
import ActiveLeaveCard from "@/components/availability/leaves/ActiveLeaveCard.vue";
import LeavesDataTable from "@/components/availability/leaves/LeavesDataTable.vue";
import LeavesCalendar from "@/components/availability/leaves/LeavesCalendar.vue";
import LeavesStatisticsOverview from "@/components/availability/leaves/LeavesStatisticsOverview.vue";
import {
  AVAILABILITY_LEAVES_create,
  AVAILABILITY_LEAVES_read_all,
} from "@/data/permission-types.js";
import { AVAILABILITY } from "@/store/modules.js";
import { GET_LEAVES } from "@/store/action-types.js";

export default {
  name: "availability-leaves",
  components: {
    NavigationBar,
    Subheader,
    SupportTools,
    TippCard,
    ActiveLeaveCard,
    LeavesDataTable,
    LeavesCalendar,
    LeavesStatisticsOverview,
  },
  data() {
    return {
      search: "",
      actions: [
        {
          title: "Erstellen",
          permission: `${AVAILABILITY_LEAVES_create}`,
          icon: "mdi-plus-circle",
          actionStyle: "textBtn",
          function: () => {
            this.$router.push({ name: "availability-leaves-new" });
          },
        },
      ],
      helpers: {
        displayMode: "table",
      },
      leavesPersonFilter: "own",
      leavesStateFilter: "active",
    };
  },
  computed: {
    leavesOutput() {
      switch (this.leavesStateFilter) {
        case "all":
          return this.allLeaves;
        case "active":
          return this.activeLeaves;
        case "upcoming":
          return this.upcomingLeaves;
        case "finished":
          return this.finishedLeaves;
        default:
          return [];
      }
    },
    leaves() {
      const leaves = this.$store.state.availability.leaves;
      return leaves.map((leave) => {
        // status is active if duration.start.timestamp is now or in the past and duration.end.timestamp is in the future
        // status is upcoming if duration.start.timestamp is in the future
        // status is finished if duration.end.timestamp is in the past

        const getLeaveStatus = (start, end) => {
          const now = new Date().getTime();
          if (start > now) {
            return "upcoming";
          } else if (end < now) {
            return "finished";
          } else {
            return "active";
          }
        };
        return {
          ...leave,
          status: getLeaveStatus(
            leave.duration.start.timestamp,
            leave.duration.end.timestamp
          ),
        };
      });
    },
    allLeaves() {
      return this.leaves;
    },
    activeLeaves() {
      const filtered = this.leaves.filter(function (obj) {
        return obj.status == "active";
      });
      return filtered;
    },
    upcomingLeaves() {
      const filtered = this.leaves.filter(function (obj) {
        return obj.status == "upcoming";
      });
      return filtered;
    },
    finishedLeaves() {
      const filtered = this.leaves.filter(function (obj) {
        return obj.status == "finished";
      });
      return filtered;
    },
    checkIfSupport() {
      return this.$store.getters["organization/checkIfSupport"];
    },
    code() {
      return [{ title: "leaves", data: this.leaves }];
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      var allowedToReadAllLeaves = this.$store.getters[
        "organization/checkPermissionByID"
      ](`${AVAILABILITY_LEAVES_read_all}`);
      if (allowedToReadAllLeaves) {
        this.$store.dispatch(`${AVAILABILITY}${GET_LEAVES}`, {
          organizationId: this.$route.params.organizationId,
          queryIsFiltered: true,
          query: {
            type: "all",
          },
          uid: auth.currentUser.uid,
        });
      } else {
        this.$store.dispatch(`${AVAILABILITY}${GET_LEAVES}`, {
          organizationId: this.$route.params.organizationId,
          queryIsFiltered: true,
          query: {
            type: "all",
            filterByUid: true,
          },
        });
      }
    },
  },
};
</script>

<style>
.transparent-background {
  background-color: transparent !important;
}
</style>
