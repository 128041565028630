<template>
  <v-card>
    <v-card-text class="d-flex">
      <v-avatar
        :color="`${color} lighten-5`"
        rounded
        class="hidden-xs-only me-3"
      >
        <v-icon dark :color="color">
          {{ icon }}
        </v-icon>
      </v-avatar>
      <div>
        <p class="text-xs mb-0">{{ title }}</p>
        <h3 v-if="value !== null" class="text-xl font-weight-semibold">
          <v-icon dark small :color="color" class="me-2 hidden-sm-and-up">
            {{ icon }} </v-icon
          >{{ value }}
        </h3>
        <v-progress-circular v-else indeterminate size="16" width="2">
        </v-progress-circular>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "statistics-single-card",
  props: {
    title: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: "primary",
      required: false,
    },
    icon: {
      type: String,
      required: true,
    },
    value: {
      required: true,
    },
  },
};
</script>
